

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import AboutUs from './AboutUs';
import IntakeForm from './IntakeForm';
import '../App.css';

const Main = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <nav>
        <div className={`menu ${menuOpen ? 'open' : ''}`}>
          <div className="menu-toggle" onClick={handleMenuToggle}>
            <img src={require('./Assets/Rad_Icon.png')} alt='Menu toggle' />
          </div>
          <ul className="menu-items">
            <li>
              <Link to="/" onClick={handleMenuToggle}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleMenuToggle}>
                About
              </Link>
            </li>
            <li>
              <Link to="/intake" onClick={handleMenuToggle}>
                Intake Form
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/intake" element={<IntakeForm />} />
        {/* <Route path="*" element={<IntakeForm />} /> */}

      </Routes>

      <footer className="footer">
        <p> &copy; {new Date().getFullYear()} Radiance Personal Injury. All rights reserved.</p>
      </footer>
    </Router>
  );
};

export default Main;
