// import React, { useState } from "react";
// import "../App.css";
// import FormHeader from "./formHeader";
// import PatientInfo from "./patientInfo";
// import CaseInfo from "./caseInfo";
// import AttorneyInfo from "./attorneyInfo";
// import FormSubmitButton from "./formSubmit";
// import axios from "axios";
// import SubmissionStatusPopup from './SubmissionStatusPopup';

// const IntakeForm = () => {
//   // eslint-disable-next-line
//   const [submissionStatus, setSubmissionStatus] = useState("");
//   const [patientFirstName, setPatientFirstName] = useState("");
//   const [patientLastName, setPatientLastName] = useState("");
//   const [patientDob, setPatientDob] = useState("");
//   const [patientSex, setPatientSex] = useState("");
//   const [patientEmail, setPatientEmail] = useState("");
//   const [patientAddress, setPatientAddress] = useState("");
//   const [patientPhone, setPatientPhone] = useState("");
//   const [selectedLanguage, setSelectedLanguage] = useState("");
//   const [translatorNeeded, setTranslatorNeeded] = useState("");
//   const [comments, setComments] = useState("");
//   // eslint-disable-next-line
//   const [formData, setFormData] = useState({});

//   // Case Info
//   const [accidentDescription, setAccidentDescription] = useState("");
//   const [dateOfInjury, setDateOfInjury] = useState("");
//   const [typeOfAccident, setTypeOfAccident] = useState("");
//   const [selectedSpecialties, setSelectedSpecialties] = useState([]);
//   const [selectedSpineOptions, setSelectedSpineOptions] = useState([]);
//   const [leftExtremities, setLeftExtremities] = useState([]);
//   const [rightExtremities, setRightExtremities] = useState([]);
//   const [selectedTreatments, setSelectedTreatments] = useState([]);
//   const [appointmentType, setAppointmentType] = useState("");
//   const [otherInfo, setOtherInfo] = useState("");
//   // eslint-disable-next-line
//   const [file2, setFile2] = useState(null);
//   const [uploadedFiles, setUploadedFiles] = useState([]);
  

//   // Attorney info
//   const [firmName, setFirmName] = useState("");
//   const [attorneyName, setAttorneyName] = useState("");
//   const [attorneyEmail, setAttorneyEmail] = useState("");
//   const [officePhone, setOfficePhone] = useState("");
//   const [caseManagerName, setCaseManagerName] = useState("");
//   const [caseManagerEmail, setCaseManagerEmail] = useState("");
//   const [caseManagerPhone, setCaseManagerPhone] = useState("");
//   const [assistantName, setAssistantName] = useState("");
//   const [assistantEmail, setAssistantEmail] = useState("");
//   const [assistantPhone, setAssistantPhone] = useState("");

//   const handlePatientInfoChange = (field, value) => {
//     switch (field) {
//       case "patientFirstName":
//         setPatientFirstName(value);
//         break;
//       case "patientLastName":
//         setPatientLastName(value);
//         break;
//       case "patientDob":
//         setPatientDob(value);
//         break;
//       case "patientSex":
//         setPatientSex(value);
//         break;
//       case "patientEmail":
//         setPatientEmail(value);
//         break;
//       case "patientAddress":
//         setPatientAddress(value);
//         break;
//       case "patientPhone":
//         setPatientPhone(value);
//         break;
//       case "selectedLanguage":
//         setSelectedLanguage(value);
//         break;
//       case "translatorNeeded":
//         setTranslatorNeeded(value);
//         break;
//       case "comments":
//         setComments(value);
//         break;
//       default:
//         break;
//     }
//   };

//   const handleCaseInfoChange = (field, value) => {
//     switch (field) {
//       case "accidentDescription":
//         setAccidentDescription(value);
//         break;
//       case "dateOfInjury":
//         setDateOfInjury(value);
//         break;
//       case "typeOfAccident":
//         setTypeOfAccident(value);
//         break;
//       case "selectedSpecialties":
//         setSelectedSpecialties(value);
//         break;
//       case "selectedSpineOptions":
//         setSelectedSpineOptions(value);
//         break;
//       case "leftExtremities":
//         setLeftExtremities(value);
//         break;
//       case "rightExtremities":
//         setRightExtremities(value);
//         break;
//       case "selectedTreatments":
//         setSelectedTreatments(value);
//         break;
//       case "appointmentType":
//         setAppointmentType(value);
//         break;
//       case "otherInfo":
//         setOtherInfo(value);
//         break;

//       default:
//         break;
//     }
//   };

//   const handleAttorneyInfoChange = (field, value) => {
//     switch (field) {
//       case "firmName":
//         setFirmName(value);
//         break;
//       case "attorneyName":
//         setAttorneyName(value);
//         break;
//       case "attorneyEmail":
//         setAttorneyEmail(value);
//         break;
//       case "officePhone":
//         setOfficePhone(value);
//         break;
//       case "caseManagerName":
//         setCaseManagerName(value);
//         break;
//       case "caseManagerEmail":
//         setCaseManagerEmail(value);
//         break;
//       case "caseManagerPhone":
//         setCaseManagerPhone(value);
//         break;
//       case "assistantName":
//         setAssistantName(value);
//         break;
//       case "assistantEmail":
//         setAssistantEmail(value);
//         break;
//       case "assistantPhone":
//         setAssistantPhone(value);
//         break;

//       default:
//         break;
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const fileNames = document.getElementById('fileNames').value;
//     const fileNamesArray = fileNames.split(',');
  

//     const emailContent = {
//       patientInfo: {
//         firstName: patientFirstName,
//         lastName: patientLastName,
//         dob: patientDob,
//         sex: patientSex,
//         email: patientEmail,
//         address: patientAddress,
//         phone: patientPhone,
//         language: selectedLanguage,
//         translatorNeeded: translatorNeeded === "yes" ? "Yes" : "No",
//         comments: comments,
//       },
//       caseInfo: {
//         accidentDescription: accidentDescription,
//         dateOfInjury: dateOfInjury,
//         typeOfAccident: typeOfAccident,
//         selectedSpecialties: selectedSpecialties,
//         selectedSpineOptions: selectedSpineOptions,
//         leftExtremities: leftExtremities,
//         rightExtremities: rightExtremities,
//         selectedTreatments: selectedTreatments,
//         appointmentType: appointmentType,
//         otherInfo: otherInfo,
//       },
//       attorneyInfo: {
//         firmName: firmName,
//         attorneyName: attorneyName,
//         attorneyEmail: attorneyEmail,
//         officePhone: officePhone,
//         caseManagerName: caseManagerName,
//         caseManagerEmail: caseManagerEmail,
//         caseManagerPhone: caseManagerPhone,
//         assistantName: assistantName,
//         assistantEmail: assistantEmail,
//         assistantPhone: assistantPhone,
//       },
//       attachments: uploadedFiles,
//       fileNames:fileNamesArray,
//     };

//     const formData = new FormData();
   

//     uploadedFiles.forEach((file) => {
//       console.log("type of file", typeof file, file);
//       formData.append(
//         "attachments",
//         new File([file.content], file.filename, { type: file.type })
//       );
//     });
//     formData.append("emailContent", JSON.stringify(emailContent));
   
    
//     try {
      
//       const response = await axios.post(
//         "https://seahorse-app-7ydhm.ondigitalocean.app/email",
//         { emailContent },
//         { headers: { "Content-Type": "multipart/form-data" } }
//       );

//       console.log(response.data);
//       setSubmissionStatus(
//         "Thank you for choosing Radiance for your client's medical needs.\n" +
//           "One of our liaisons will be assisting with your request shortly.\n\n" +
//           "For urgent matters, please contact us at 818.661.4200."
//       );

//       clearForm();
     
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const clearForm = () => {
//     // Clear Patient Info
//     setPatientFirstName("");
//     setPatientLastName("");
//     setPatientDob("");
//     setPatientSex("");
//     setPatientEmail("");
//     setPatientAddress("");
//     setPatientPhone("");
//     setSelectedLanguage("");
//     setTranslatorNeeded("");
//     setComments("");
  
//     // Clear Case Info
//     setAccidentDescription("");
//     setDateOfInjury("");
//     setTypeOfAccident("");
//     setSelectedSpecialties([]);
//     setSelectedSpineOptions([]);
//     setLeftExtremities([]);
//     setRightExtremities([]);
//     setSelectedTreatments([]);
//     setAppointmentType("");
//     setOtherInfo("");
    
//     // Clear Attorney Info
//     setFirmName("");
//     setAttorneyName("");
//     setAttorneyEmail("");
//     setOfficePhone("");
//     setCaseManagerName("");
//     setCaseManagerEmail("");
//     setCaseManagerPhone("");
//     setAssistantName("");
//     setAssistantEmail("");
//     setAssistantPhone("");
    
//     setTimeout(() => {
//       setSubmissionStatus("");
//     }, 5000);
//     setUploadedFiles([]);
    
//   };

//   function toggleCheckboxValue(array, value) {
//     if (array.includes(value)) {
//       return array.filter((item) => item !== value);
//     } else {
//       return [...array, value];
//     }
//   }

//   function handleCheckboxChange(value, stateArray, setStateArray) {
//     setStateArray(toggleCheckboxValue(stateArray, value));
//   }

//   function handleAppointmentTypeChange(value) {
//     setAppointmentType(value);
//   }

//   function handleOtherInfoChange(value) {
//     setOtherInfo(value);
//   }

//   const handleFileUpload = async (event) => {
//     const files = event.target.files;
   
//     const fileNames = Array.from(files).map((file) => file.name).join(',');

//     document.getElementById('fileNames').value = fileNames;


//     const filePromises = Array.from(files).map((file) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();

//         reader.onloadend = () => {
//           const contentArray = new Uint8Array(reader.result);
//           // eslint-disable-next-line
//           const base64Content = btoa(String.fromCharCode(...contentArray));

//           resolve({
//             filename: file.name,
//             content: new base64Content(reader.result),
//             type: file.type,
//           });
//         };

//         reader.onerror = reject;

//         reader.readAsArrayBuffer(file);
//       });
//     });

//     try {
//       const attachments = await Promise.all(filePromises);
//       setUploadedFiles((prevUploadedFiles) => [
//         ...prevUploadedFiles,
//         ...attachments,
//       ]);
//       const updatedUploadedFiles = uploadedFiles.map((uploadedFile, index) => {
//         return {
//           ...uploadedFile,
//           filename: fileNames.split(',')[index], 
//         };
//       });
  
//       setUploadedFiles(updatedUploadedFiles);
  
//     } catch (error) {
//       console.error("Error uploading files:", error);
//     }
//   };

//   const handleAccidentDescriptionChange = (description) => {
//     setAccidentDescription(description);
//   };

//   return (
//     <div className="intake-form-container">
//           <div className="intake-crescent">
//       <svg
//         viewBox="0 0 241.88922 576"
//         preserveAspectRatio="none"
//         xmlns="http://www.w3.org/2000/svg"
//         role="img"
//         aria-label="Decorative half circle shape"
//       >
//         <path d="m128.60921 0c-5.95449 8.91665-11.61559 18.01208-16.84771 27.37516-6.36639 11.39289-12.06065 23.10846-17.17417 35.11448-7.88554 18.51437-14.16695 37.56114-18.96088 57.09579-3.18487 12.97787-5.65012 26.09759-7.50658 39.33595-1.18832 8.47377-1.91138 16.99226-2.72536 25.50246-.30412 3.17961-.3893 6.38039-.56424 9.57207-.49476 9.02652-.67521 18.06386-.43738 27.09662.22917 8.7037.57994 17.40405 1.2724 26.09302.80054 10.04521 1.95221 20.041 3.38424 30.01161 1.60397 11.16769 3.71006 22.24002 6.16592 33.24832 3.57408 16.02075 8.09169 31.77829 13.41437 47.2995 5.24427 15.29257 11.29015 30.26535 18.09399 44.93906 11.36773 24.51657 24.746 47.87924 40.04411 70.14124 8.88202 12.92523 18.38478 25.38531 28.51964 37.36206 6.92215 8.18011 14.13173 16.09149 21.43832 23.93103 6.66339 7.14941 13.73906 13.85956 20.84544 20.53412 7.45583 7.00287 15.19977 13.70087 23.11728 20.18817.42888.3515.8017.77136 1.20062 1.15936-80.61957 0-161.23914 0-241.88936 0 0-192 0-384 .00102-576 42.87013-.00002 85.73923-.00002 128.60833-.00002z" />
//       </svg>
//       </div>
//       <FormHeader />

//       <h1>Intake Form</h1>
//       <form onSubmit={handleSubmit} encType="multipart/form-data">
//         <PatientInfo
//           patientFirstName={patientFirstName}
//           setPatientFirstName={setPatientFirstName}
//           patientLastName={patientLastName}
//           setPatientLastName={setPatientLastName}
//           patientDob={patientDob}
//           setPatientDob={setPatientDob}
//           patientSex={patientSex}
//           setPatientSex={setPatientSex}
//           patientEmail={patientEmail}
//           setPatientEmail={setPatientEmail}
//           patientAddress={patientAddress}
//           setPatientAddress={setPatientAddress}
//           patientPhone={patientPhone}
//           setPatientPhone={setPatientPhone}
//           selectedLanguage={selectedLanguage}
//           setSelectedLanguage={setSelectedLanguage}
//           translatorNeeded={translatorNeeded}
//           setTranslatorNeeded={setTranslatorNeeded}
//           comments={comments}
//           setComments={setComments}
//           handlePatientInfoChange={handlePatientInfoChange}
//         />
//         <CaseInfo
//           accidentDescription={accidentDescription}
//           dateOfInjury={dateOfInjury}
//           typeOfAccident={typeOfAccident}
//           setTypeOfAccident={setTypeOfAccident}
//           selectedSpecialties={selectedSpecialties}
//           selectedSpineOptions={selectedSpineOptions}
//           leftExtremities={leftExtremities}
//           rightExtremities={rightExtremities}
//           selectedTreatments={selectedTreatments}
//           appointmentType={appointmentType}
//           otherInfo={otherInfo}
//           setLeftExtremities={setLeftExtremities}
//           setRightExtremities={setRightExtremities}
//           handleAccidentDescriptionChange={handleAccidentDescriptionChange}
//           handleCheckboxChange={handleCheckboxChange}
//           handleAppointmentTypeChange={handleAppointmentTypeChange}
//           handleOtherInfoChange={handleOtherInfoChange}
//           handleFileUpload={handleFileUpload}
//           handleCaseInfoChange={handleCaseInfoChange}
//           uploadedFiles={uploadedFiles}
//           setUploadedFiles={setUploadedFiles}
//         />
//         <AttorneyInfo
//           firmName={firmName}
//           setFirmName={setFirmName}
//           attorneyName={attorneyName}
//           setAttorneyName={setAttorneyName}
//           attorneyEmail={attorneyEmail}
//           setAttorneyEmail={setAttorneyEmail}
//           officePhone={officePhone}
//           setOfficePhone={setOfficePhone}
//           caseManagerName={caseManagerName}
//           setCaseManagerName={setCaseManagerName}
//           caseManagerEmail={caseManagerEmail}
//           setCaseManagerEmail={setCaseManagerEmail}
//           caseManagerPhone={caseManagerPhone}
//           setCaseManagerPhone={setCaseManagerPhone}
//           assistantName={assistantName}
//           setAssistantName={setAssistantName}
//           assistantEmail={assistantEmail}
//           setAssistantEmail={setAssistantEmail}
//           assistantPhone={assistantPhone}
//           setAssistantPhone={setAssistantPhone}
//           handleAttorneyInfoChange={handleAttorneyInfoChange}
//         />
//         <FormSubmitButton
//           handleSubmit={handleSubmit}
//           handleFileUpload={handleFileUpload}
//           submissionStatus={submissionStatus}
//         />
//         {submissionStatus && (
//         <SubmissionStatusPopup
//           message={submissionStatus}
//           onClose={() => setSubmissionStatus("")} // Clear the submission status when closed
//         />
//       )}

//       </form>
//     </div>
//   );
// };

// export default IntakeForm;



import React, { useEffect, useRef } from 'react';
import '../App.css';


const IntakeForm = () => {
  const formContainerRef = useRef(null);

  useEffect(() => {
    const createFormIframe = () => {
      try {
        if (!formContainerRef.current.querySelector('iframe')) {
          const f = document.createElement('iframe');
          f.src =
            'https://forms.zohopublic.com/adorfman/form/Intake/formperma/h-luA_B5FLGg78FswkADFqSFQIF0g9K0kuNXpvuzfXM?zf_rszfm=1&zf_enablecamera=true';
          f.style.border = 'none';
          f.style.height = '2023px';
          f.style.width = '90%';
          f.style.transition = 'all 0.5s ease';
          f.setAttribute('allow', 'camera;');

          const d = formContainerRef.current;
          d.appendChild(f);

          window.addEventListener('message', (event) => {
            const evntData = event.data;
            if (evntData && evntData.constructor === String) {
              const zf_ifrm_data = evntData.split('|');
              if (zf_ifrm_data.length === 2) {
                const zf_perma = zf_ifrm_data[0];
                const zf_ifrm_ht_nw = `${parseInt(zf_ifrm_data[1], 10) + 15}px`;
                const iframe = d.querySelector('iframe');
                if (
                  iframe.src.indexOf('formperma') > 0 &&
                  iframe.src.indexOf(zf_perma) > 0
                ) {
                  const prevIframeHeight = iframe.style.height;
                  if (prevIframeHeight !== zf_ifrm_ht_nw) {
                    iframe.style.height = zf_ifrm_ht_nw;
                  }
                }
              }
            }
          });
        }
      } catch (e) {}
    };

    createFormIframe();
  }, []);

  return (
    <div className="intake-form-container">
      <div ref={formContainerRef}></div>
    
    </div>
  );
};

export default IntakeForm;
