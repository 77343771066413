import React from 'react';
import { Link } from 'react-router-dom'
import Rad_PI_Logo from './Assets/Rad_PI_Logo.png';
import '../App.css'
import { useNavigate } from 'react-router-dom';



const HomePage = () => {
  const navigate = useNavigate();

  const handleIntakeFormClick = () => {
    navigate('/intake');
  };
  const handleBrochureClick = () => {
    window.open(process.env.PUBLIC_URL + '/Brochure.pdf', '_blank');
  
  };
  return (
    
    <div className="homepage-container">
    <div className="crescent">
      <svg
        viewBox="0 0 241.88922 576"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Decorative half circle shape"
      >
        <path d="m128.60921 0c-5.95449 8.91665-11.61559 18.01208-16.84771 27.37516-6.36639 11.39289-12.06065 23.10846-17.17417 35.11448-7.88554 18.51437-14.16695 37.56114-18.96088 57.09579-3.18487 12.97787-5.65012 26.09759-7.50658 39.33595-1.18832 8.47377-1.91138 16.99226-2.72536 25.50246-.30412 3.17961-.3893 6.38039-.56424 9.57207-.49476 9.02652-.67521 18.06386-.43738 27.09662.22917 8.7037.57994 17.40405 1.2724 26.09302.80054 10.04521 1.95221 20.041 3.38424 30.01161 1.60397 11.16769 3.71006 22.24002 6.16592 33.24832 3.57408 16.02075 8.09169 31.77829 13.41437 47.2995 5.24427 15.29257 11.29015 30.26535 18.09399 44.93906 11.36773 24.51657 24.746 47.87924 40.04411 70.14124 8.88202 12.92523 18.38478 25.38531 28.51964 37.36206 6.92215 8.18011 14.13173 16.09149 21.43832 23.93103 6.66339 7.14941 13.73906 13.85956 20.84544 20.53412 7.45583 7.00287 15.19977 13.70087 23.11728 20.18817.42888.3515.8017.77136 1.20062 1.15936-80.61957 0-161.23914 0-241.88936 0 0-192 0-384 .00102-576 42.87013-.00002 85.73923-.00002 128.60833-.00002z" />
      </svg>
    </div>

    <div className="homepage-content">
      
        <img src={Rad_PI_Logo} className="Rad-logo" alt="Radiance PI" />
      </div>

      <div className="content">
        <a href="mailto:info@radiance-pi.com">Info@Radiance-PI.com</a>

        <div className="intake-container">
        <div className="button-container">
            <Link to="/intake">
              <button className="IntakeButton" onClick={handleIntakeFormClick}>
                Intake Form
              </button>         
            </Link>
            <a
              href="/Brochure.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="RadianceBrochureButton" onClick={handleBrochureClick}>
                Radiance Brochure
              </button>
            </a>
          </div>
          </div>
      </div>
    </div>
  
);
};

export default HomePage;
